<template>
<div class="weui-msg">
  <div class="weui-msg__icon-area"><i class="weui-icon-success weui-icon_msg"></i></div>
  <div class="weui-msg__text-area">
    <h2 class="weui-msg__title">操作成功</h2>
    <p class="weui-msg__desc"></p>
  </div>
  <!-- 
  <div class="weui-msg__opr-area">
    <p class="weui-btn-area">
      <a href="javascript:;" class="weui-btn weui-btn_primary">推荐操作</a>
      <a href="javascript:;" class="weui-btn weui-btn_default">辅助操作</a>
    </p>
  </div>
   -->
  <RLFooter/>
</div>
</template>

<script>

export default {
  name: 'success',
  components: {
      
  },
  data(){
    return {
     
    };
  },
  methods:{
     
  },
   created() {
     
  },
}
</script>

<style scoped>


</style>
